export default [
  {
    pk: 1,
    page_name: 'Riesgo',
    indicators: [
      {
        name: 'Riesgo',
        uuid: 'e7087cae-b4bc-479e-95bb-3776e7413c11'
      }
    ]
  },
  {
    pk: 2,
    page_name: 'Tamizaje',
    indicators: [
      {
        name: 'Tamizaje',
        uuid: '5710bc5f-cbc3-4e23-b3b6-4b5c6b74f354'
      }
    ]
  },
  {
    pk: 3,
    page_name: 'Oncología',
    indicators: [
      {
        name: 'Oncología',
        uuid: '6730287b-022b-4f9d-bffb-8d127f490f25'
      }
    ]
  },
  {
    pk: 4,
    page_name: 'Unindad funcional 4',
    indicators: [
      {
        name: 'Oncología',
        uuid: '6730287b-022b-4f9d-bffb-8d127f490f25'
      }
    ]
  },
  {
    pk: 5,
    page_name: 'Unindad funcional 5',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  },
  {
    pk: 6,
    page_name: 'Unindad funcional 6',
    indicators: [
      {
        name: 'Indicador',
        uuid: ''
      }
    ]
  }
] as WorkUnit[];
